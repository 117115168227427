import React from 'react';
import './App.css';

const App: React.FC = () => {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Jumbo Video Poker</h1>
          <a href='https://play.google.com/store/apps/details?id=com.lambtongames.jumbovideopokerfree&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
            <img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/>
          </a>
      </header>
    </div>
  );
}

export default App;
